import FrontText from "../../FrontText";

export default function FrontContactInputLabel({ value, className = '', children, ...props }) {
    return (
        <label {...props} className={`block text-black mb-2 ` + className}>
            <FrontText textSize={'16/20'} className="font-medium">
                {value ? value : children}
            </FrontText>
        </label>
    );
}

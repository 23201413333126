import { useTranslation } from 'react-i18next';

const FrontLangButton = ({ callback, className }) => {
    const { t, i18n } = useTranslation();

    return (
        <button
            title={t("menu.change_language")}
            onClick={callback}
            className={className}
        >
            {i18n.resolvedLanguage === "pl" ? (
                <img
                    src="/storage/icons/en_circle.png"
                    alt={t("menu.change_language")}
                    height={22}
                    width={22}
                    className="rounded-full mr-c15"
                    suppressHydrationWarning
                />
            ) : (
                <img
                    src="/storage/icons/pl_circle.png"
                    alt={t("menu.change_language")}
                    height={22}
                    width={22}
                    className="rounded-full mr-c15"
                    suppressHydrationWarning
                />
            )}
        </button>
    );
};

export default FrontLangButton;

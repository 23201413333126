import { useEffect, useState, useRef } from "react";
import ApplicationLogo from "@/Components/ApplicationLogo";
import { Head, Link, usePage, router } from "@inertiajs/react";
import Breadcrumbs from "@/Components/System/Breadcrumbs/Breadcrumbs";
import { Icon } from "@iconify/react";
import NavMenu from "@/Components/System/Nav/NavMenu";
import { Transition } from "@headlessui/react";
import NavLink from "@/Components/System/Nav/NavLink";
import SessionMessage from "@/Components/System/SessionMessage/SessionMessage";
import ActionButton from "@/Components/System/Buttons/ActionButton";
import Container from "@/Components/Grid/Container";
import Row from "@/Components/Grid/Row";
import Column from "@/Components/Grid/Column";
import FrontText from "@/Components/Front/FrontText";
import FrontClassicButton from "@/Components/Front/Buttons/FrontClassicButton";
import FrontFooterMenu from "@/Components/Front/Menus/FrontFooterMenu";
import FrontNewsletter from "@/Components/Front/Forms/FrontNewsletter";
import FrontBreadcrumbs from "@/Components/Front/Blocks/FrontBreadcrumbs";
import resolveLocaleRoute from "@/Helpers/resolveLocaleRoute";
import { useTranslation } from 'react-i18next';
import FrontHeaderMenu from "@/Components/Front/Menus/FrontHeaderMenu";

// import * as ApolloPkg from '@apollo/client';
import gql from 'graphql-tag';
import { useQuery } from "@apollo/client/react/hooks/hooks.cjs";
import WebpImage from "@/Components/Front/Blocks/WebpImage";
import FrontLangButton from "@/Components/Front/Partials/FrontLangButton";
import FrontPopup from "./FrontPopup";
// const { useQuery } = ApolloPkg;
// import { gql } from '@apollo/client/core/core.cjs';
// import {gql} from '@apollo/client/core/core.cjs';

// import { gql } from "@apollo/client/apollo-client.min.cjs";
// import { useQuery, gql } from "@apollo/client";
// import { useQuery, gql } from "@apollo/client";
// import route from '@ziggyJs';

const FrontLayout = ({ children, newsletterImage, newsletterQuote, breadcrumbs = [] }) => {
    const { t, i18n } = useTranslation();
    const { url } = usePage();

    const [newLocale, setNewLocale] = useState(i18n.resolvedLanguage === 'pl' ? 'en' : 'pl');
    const currentRoute = route().current();
    const taxSlug = url.split('/')[i18n.resolvedLanguage === 'pl' ? 2 : 3];
    const postSlug = url.split('/')[i18n.resolvedLanguage === 'pl' ? 3 : 4];

    const POST_SECOND_LANG = gql`
        query GetPost($slug: ID!, $lang: LanguageCodeEnum!) {
            post(
                id: $slug
                idType: SLUG
            ) {
                translation(language: $lang){
                    slug
                }
            }
        }
    `;

    const TAX_SECOND_LANG = gql`
        query GetTax($slug: [String], $lang: LanguageCodeEnum!) {
            terms(where: {
                slug: $slug
            }) {
                nodes {
                    slug
                        ...on Category {
                            translation(language: $lang) {
                                slug
                            }
                        }
                }
            }
        }
    `;

    const { loading, error, data } = useQuery(POST_SECOND_LANG, {
        variables: { slug: postSlug, lang: newLocale.toUpperCase() },
        skip: currentRoute !== 'front.news.single' && currentRoute !== 'front.news.single.en',
        ssr: true,
    });

    const { loading: loadingTax, error: errorTax, data: dataTax } = useQuery(TAX_SECOND_LANG, {
        variables: { slug: taxSlug, lang: newLocale.toUpperCase() },
        skip: currentRoute !== 'front.news.single' && currentRoute !== 'front.news.single.en' && currentRoute !== 'front.news.loop' && currentRoute !== 'front.news.loop.en',
        ssr: true,
    });

    const handleChangeLocale = () => {
        i18n.changeLanguage(newLocale);
        setNewLocale(newLocale);

        if (currentRoute === 'front.news.loop' || currentRoute === 'front.news.loop.en') {
            if (!loadingTax && dataTax && dataTax.terms) {
                const newTax = dataTax.terms.nodes[0].translation.slug;

                if (newLocale === 'en') {
                    router.visit(
                        route('front.news.loop.en', { taxonomy: newTax })
                    );
                }

                if (newLocale === 'pl') {
                    router.visit(
                        route('front.news.loop', { taxonomy: newTax })
                    );
                }
            }
            return;
        }

        if (currentRoute != 'front.news.single' && currentRoute !== 'front.news.single.en') {
            if (newLocale === 'pl') {
                router.visit(`${route(currentRoute.substring(0, currentRoute.length - 3), route().params)}`,
                    { preserveScroll: true }
                );
            } else {
                router.visit(`${route(currentRoute + '.en', route().params)}`,
                    { preserveScroll: true }
                );
            }
        } else {
            if (!loading && !loadingTax && data && dataTax && data.post && dataTax.terms) {
                const newSlug = data.post.translation.slug;
                const newTax = dataTax.terms.nodes[0].translation.slug;

                if (newLocale === 'en') {
                    router.visit(
                        route('front.news.single.en', { taxonomy: newTax, slug: newSlug })
                    );
                }

                if (newLocale === 'pl') {
                    router.visit(
                        route('front.news.single', { taxonomy: newTax, slug: newSlug })
                    );
                }
            }
        }
    };

    const headerRef = useRef(null);
    const [headerHeight, setHeaderHeight] = useState(null);

    /* =================== [Show Mobile Nav] =================== */
    const [isMenuVisible, setIsMenuVisible] = useState(false)

    const showNav = (e) => {
        setIsMenuVisible((prev) => {
            const isMenuVisible = !prev;

            if (isMenuVisible) {
                document.body.classList.add('overflow-hidden');
            } else {
                document.body.classList.remove('overflow-hidden');
            }

            return isMenuVisible;
        });
    }

    useEffect(() => {
        if (isMenuVisible) {
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
        }

        return () => {
            document.body.classList.remove('overflow-hidden');
        };
    }, [isMenuVisible]);


    const handler = () => {
        setIsMenuVisible(false);

        if (headerRef.current) {
            setHeaderHeight(headerRef.current.offsetHeight);
        }
    };

    /* =================== [Resize handler] =================== */
    useEffect(() => {
        window.addEventListener("resize", handler);
        return () => window.removeEventListener("resize", handler);
    }, []);

    return (
        <>
            <header className="bg-new-blue fixed top-0 w-full z-40" ref={headerRef} onLoad={handler}>
                <div className="relative">
                    <Container>
                        <Row>
                            <Column className="flex justify-between items-center">
                                <Link href={resolveLocaleRoute('front.home', null, i18n.resolvedLanguage)} title={t('menu.homepage_title')}>
                                    <WebpImage
                                        className='lg:h-auto h-8 relative z-50 object-contain object-left'
                                        sources={[
                                            {
                                                width: 1,
                                                path: `/storage/images/front-logo`,
                                                mime: 'jpg'
                                            },
                                        ]}
                                        alt={'Warehousemarket.pl'}
                                        fallback={'/storage/images/front-logo.png'}
                                        seo={{
                                            width: 225,
                                            height: 51
                                        }}
                                    />
                                </Link>

                                <div className="flex items-center">

                                    <FrontHeaderMenu mobileVisible={isMenuVisible} headerHeight={headerHeight} nav={t('menu.nav_menu', { returnObjects: true })} />

                                    <span className="hidden border-l border-white lg:inline-block h-[21px] lg:ml-c10 ml-2 lg:mr-c18 mr-3"></span>

                                    <FrontLangButton callback={handleChangeLocale} className="lg:block hidden" />

                                    <Link href={resolveLocaleRoute('front.contact', null, i18n.resolvedLanguage)} title={t('menu.contact_with_us')} className="lg:block hidden">
                                        <FrontClassicButton className=" min-w-[177px] uppercase">
                                            <span>{t('menu.contact_with_us')}</span>
                                        </FrontClassicButton>
                                    </Link>
                                </div>
                                <div className="flex lg:hidden items-center">
                                    <FrontLangButton callback={handleChangeLocale} />
                                    <button className={`text-white hover:text-yellow transition-all ease-in-out relative z-50 py-[17.5px] ${isMenuVisible && 'hover:rotate-90'}`} onClick={showNav}>
                                        <Icon icon={isMenuVisible ? 'ph:x-bold' : 'quill:hamburger'} width={42} height={35} />
                                    </button>
                                </div>
                            </Column>
                        </Row>
                    </Container>
                </div>
            </header>

            <div className="page-wrapper mt-c87" style={{ marginTop: headerHeight }}>
                <main>
                    {breadcrumbs.length > 0 && (
                        <FrontBreadcrumbs breadcrumbs={breadcrumbs} />
                    )}
                    {children}
                </main>
            </div>



            <FrontNewsletter image={newsletterImage} quoteKey={newsletterQuote} />

            <footer className="bg-new-blue pt-c234 pb-c37 z-20 relative">
                <Container>
                    <Row>
                        <Column className="flex items-center justify-between lg:flex-row flex-col 2xl:gap-25 xl:gap-20 lg:gap-16 gap-y-10">
                            <Link href={resolveLocaleRoute("front.home", null, i18n.resolvedLanguage)} title={t('menu.homepage_title')} className="shrink-0">
                                <WebpImage
                                    sources={[
                                        {
                                            width: 1,
                                            path: `/storage/images/front-logo`,
                                            mime: 'jpg'
                                        },
                                    ]}
                                    alt={'Warehousemarket.pl'}
                                    fallback={'/storage/images/front-logo.png'}
                                    seo={{
                                        width: 225,
                                        height: 51
                                    }}
                                />
                            </Link>

                            <div className="w-full sm:block flex justify-center">
                                <FrontFooterMenu
                                    firstColLinks={[
                                        {
                                            title: t('footer.warehouses'),
                                            href: "front.warehouses.all",
                                        },
                                        {
                                            title: t('footer.news'),
                                            href: 'front.news',
                                        },
                                        {
                                            title: t('footer.services'),
                                            href: "front.services",
                                        },
                                        {
                                            title: t('footer.about_us'),
                                            href: "front.aboutUs",
                                        },
                                        {
                                            title: t('footer.contact'),
                                            href: 'front.contact',
                                        },
                                    ]}
                                    secondColLinks={[
                                        {
                                            title: t('footer.warehouses_poznan'),
                                            href: 'front.warehouses',
                                            params: { searchRegion: 'poznan' },
                                        },
                                        {
                                            title: t('footer.warehouses_warszawa'),
                                            href: 'front.warehouses',
                                            params: { searchRegion: 'warszawa' },
                                        },
                                        {
                                            title: t('footer.warehouses_wroclaw'),
                                            href: 'front.warehouses',
                                            params: { searchRegion: 'wroclaw' },
                                        },
                                        {
                                            title: t('footer.warehouses_katowice'),
                                            href: 'front.warehouses',
                                            params: { searchRegion: 'katowice' },
                                        },
                                        {
                                            title: t('footer.warehouses_rzeszow'),
                                            href: 'front.warehouses',
                                            params: { searchRegion: 'rzeszow' },
                                        },
                                        {
                                            title: t('footer.warehouses_krakow'),
                                            href: 'front.warehouses',
                                            params: { searchRegion: 'krakow' },
                                        },
                                    ]}
                                    thirdColLinks={[
                                        {
                                            title: t('footer.warehouses_gdansk'),
                                            href: 'front.warehouses',
                                            params: { searchRegion: 'gdansk' },
                                        },
                                        {
                                            title: t('footer.warehouses_szczecin'),
                                            href: 'front.warehouses',
                                            params: { searchRegion: 'szczecin' },
                                        },
                                        {
                                            title: t('footer.warehouses_tychy'),
                                            href: 'front.warehouses',
                                            params: { searchRegion: 'tychy' },
                                        },
                                        {
                                            title: t('footer.warehouses_sosnowiec'),
                                            href: 'front.warehouses',
                                            params: { searchRegion: 'sosnowiec' },
                                        },
                                        {
                                            title: t('footer.warehouses_legnica'),
                                            href: 'front.warehouses',
                                            params: { searchRegion: 'legnica' },
                                        },
                                        {
                                            title: t('footer.warehouses_gliwice'),
                                            href: 'front.warehouses',
                                            params: { searchRegion: 'gliwice' },
                                        },
                                    ]}
                                    fourthColLinks={[
                                        {
                                            title: t('footer.warehouses_gdynia'),
                                            href: 'front.warehouses',
                                            params: { searchRegion: 'gdynia' },
                                        },
                                        {
                                            title: t('footer.warehouses_chorzow'),
                                            href: 'front.warehouses',
                                            params: { searchRegion: 'chorzow' },
                                        },
                                        {
                                            title: t('footer.warehouses_lublin'),
                                            href: 'front.warehouses',
                                            params: { searchRegion: 'lublin' },
                                        },
                                        {
                                            title: t('footer.warehouses_gadki'),
                                            href: 'front.warehouses',
                                            params: { searchRegion: 'gadki' },
                                        },
                                        {
                                            title: t('footer.warehouses_lodz'),
                                            href: 'front.warehouses',
                                            params: { searchRegion: 'lodz' },
                                        },
                                        {
                                            title: t('footer.warehouses_piaseczno'),
                                            href: 'front.warehouses',
                                            params: { searchRegion: 'piaseczno' },
                                        },
                                    ]}
                                />
                            </div>
                        </Column>

                        <Column>
                            <hr className="border-C5C3C3 mb-9 mt-18" />
                            <Row>
                                <Column xl={7} className={`ml-auto flex`}>
                                    <FrontText textSize='14/17' customLinkStyles className="mr-auto">
                                        <a title={t('footer.privacy_policy')} target="_blank" href={`/storage/savills_polityka_prywatnosci.pdf`} className=" text-white hover:text-yellow transition-all ease-in-out duration-200 mx-auto block">{t('footer.privacy_policy')}</a>
                                    </FrontText>
                                    <FrontText textSize='14/17' customLinkStyles className="text-white">
                                        &copy; Copyright by Savills
                                    </FrontText>
                                </Column>
                            </Row>
                        </Column>
                    </Row>
                </Container>
            </footer>
            <FrontPopup/>
        </>
    );
};

export default FrontLayout;

import FrontContactAcceptance from "@/Components/Front/Forms/Contact/FrontContactAcceptance";
import FrontContactInputLabel from "@/Components/Front/Forms/Contact/FrontContactInputLabel";
import FrontContactTextInput from "@/Components/Front/Forms/Contact/FrontContactTextInput";
import FrontText from "@/Components/Front/FrontText";
import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react";
import { useForm, usePage } from "@inertiajs/react";
import { Fragment, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

const FrontPopup = () => {
    const { t } = useTranslation();
    const { error } = usePage().props?.flash;
    const [show, setShow] = useState(false);

    const isServer = typeof window === 'undefined'

    const { data, setData, post, reset, wasSuccessful } = useForm({
        name_surname: "",
        email: "",
        referer: 'Popup',
        marketing_acceptance: "",
        recaptcha_token: "",
    });

    const submit = async (e) => {
        e.preventDefault();

        const token = await grecaptcha.execute(import.meta.env.VITE_RECAPTCHA_SITE_KEY, { action: 'submit' });
        setData("recaptcha_token", token);
    };

    useEffect(() => {
        if (data.recaptcha_token) {
            post(route('send-contact-form-popup'), {
                preserveScroll: true,
                onSuccess: () => {
                    if(!error) {
                        reset();

                        if (!isServer) {
                            document.cookie = `popupContactForm=true;max-age=604800;domain=${window.location.hostname}`
                        }
    
                        if(!error){
                            closePopup();
                        }
                    }

                },
            });
        }
    }, [data.recaptcha_token])

    const closePopup = () => {
        setShow(false);

        if (!isServer) {
            const maxAge = 1 * 24 * 60 * 60 * 1000;

            document.cookie = `popupContactForm=true;max-age=${maxAge};domain=${window.location.hostname}`
        }
    }

    useEffect(() => {
        if (isServer) {
            return;
        }
        if (document.cookie.indexOf('popupContactForm') == -1) {
            setTimeout(() => setShow(true), 60000);
        }
    }, [isServer]);

    const transLink = {
        1: <a title={t('footer.privacy_policy')} target="_blank" href={`/storage/savills_polityka_prywatnosci.pdf`} className="text-dark-blue underline">{t('footer.privacy_policy')}</a>
    }

    return (
        <Transition show={show} as={Fragment} leave="duration-200">
            <Dialog
                as="div"
                id="modal"
                className="fixed inset-0 flex overflow-y-auto px-4 py-6 sm:px-0 items-center z-50 transform transition-all"
                onClose={closePopup}
            >
                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="absolute inset-0 bg-black/80" />
                </TransitionChild>

                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >

                    <DialogPanel
                        className={`mb-6 rounded-xl overflow-hidden shadow-xl transform transition-all sm:w-full sm:mx-auto max-w-lg md:my-0 my-auto`}
                    >
                        <button className="w-10 h-10 text-white absolute right-0 top-0 font-bold" type="button" onClick={closePopup}>
                            X
                        </button>
                        <div className="bg-dark-blue-standard pt-10 pb-5 px-6 text-center">
                            <FrontText className="text-yellow font-bold mb-5" textSize={'32/39'}>
                                <p>{t('popup.heading')}</p>
                            </FrontText>
                            <FrontText className="text-white" textSize={'20/24'}>
                                <p>{t('popup.subHeading')}</p>
                            </FrontText>
                        </div>
                        <div className="px-6 py-5 bg-white">
                            <FrontText className="text-new-blue font-bold mb-5" textSize={'14/16'}>
                                <p>{t('popup.formEntry')}</p>
                            </FrontText>
                            <form onSubmit={submit}>
                                <div className="grid md:grid-cols-2 grid-cols-1 md:gap-x-5 gap-x-0">
                                    <div>
                                        <FrontContactInputLabel
                                            htmlFor="name_surname"
                                            value={t("popup.namePlaceholder")}
                                        />
                                        <FrontContactTextInput
                                            id="name_surname"
                                            type="text"
                                            name="name_surname"
                                            value={data.name_surname}
                                            className="w-full"
                                            onChange={(e) => setData("name_surname", e.target.value)}
                                            required
                                            isFocused
                                        />
                                    </div>

                                    <div>
                                        <FrontContactInputLabel
                                            htmlFor="email"
                                            value={t("popup.emailPlaceholder")}
                                        />

                                        <FrontContactTextInput
                                            id="email"
                                            type="email"
                                            name="email"
                                            value={data.email}
                                            className="w-full"
                                            onChange={(e) => setData("email", e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                                <div>
                                    <button
                                        type="submit"
                                        className="bg-light-blue h-10 w-full mb-5 rounded-lg mx-auto block border-2 border-light-blue text-white hover:bg-white hover:text-light-blue transition-all ease-in-out duration-300"
                                    >
                                        <FrontText
                                            textSize="14/17"
                                            className="uppercase font-semibold"
                                        >
                                            {t("popup.buttonText")}
                                        </FrontText>
                                    </button>
                                </div>
                            </form>
                            <div className="rodo-wrapper">
                                <p className="text-10 text-new-blue">
                                    {t('popup.acceptance1')}
                                </p>
                                <div className="text-10 text-new-blue mt-3">
                                    <Trans i18nKey={'popup.acceptance2'} components={transLink} />
                                </div>
                                <FrontContactAcceptance
                                    id="marketing_acceptance"
                                    name="marketing_acceptance"
                                    className="mt-3"
                                    checked={data.marketing_acceptance}
                                    popupAcceptance
                                    required
                                    onChange={(e) =>
                                        setData(
                                            "marketing_acceptance",
                                            e.target.checked ? true : false
                                        )
                                    }
                                >
                                    <p>{t("popup.acceptance3")}</p>
                                </FrontContactAcceptance>
                            </div>
                        </div>
                    </DialogPanel>
                </TransitionChild>
            </Dialog>
        </Transition>
    )
}

export default FrontPopup;
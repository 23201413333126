import Column from "@/Components/Grid/Column";
import Container from "@/Components/Grid/Container";
import Row from "@/Components/Grid/Row";
import { Link } from "@inertiajs/react";
import React from "react";
import FrontText from "../FrontText";
import { useTranslation } from 'react-i18next';
import resolveLocaleRoute from "@/Helpers/resolveLocaleRoute";

const FrontBreadcrumbs = ({ breadcrumbs }) => {
    const { t, i18n } = useTranslation();

    return (
        <div className="bg-F3F3F3 py-4 uppercase">
            <Container>
                <Row>
                    <Column>
                        <nav className="breadcrumbs overflow-x-auto max-w-full whitespace-nowrap no-scrollbar">
                            <Link
                                href={resolveLocaleRoute("front.home", null, i18n.resolvedLanguage)}
                                title={t('breadcrumbs.homepage')}
                                className="text-gray-text hover:text-heading-blue transition-all ease-in-out duration-150 font-medium"
                            >
                                <FrontText textSize='12/15' className="inline">
                                    {t('breadcrumbs.homepage')}
                                </FrontText>
                            </Link>
                            {breadcrumbs.map((el, id) => {
                                if (el.current === true) {
                                    return (
                                        <React.Fragment key={id}>
                                            <FrontText textSize='12/15' className="text-gray-text font-medium inline">
                                                {" "}
                                                /{" "}
                                            </FrontText>
                                            <FrontText textSize='12/15' className="text-heading-blue font-medium inline">
                                                {el.title}
                                            </FrontText>
                                        </React.Fragment>
                                    );
                                }
                                return (
                                    <React.Fragment key={id}>
                                        <FrontText textSize='12/15' className="text-gray-text font-medium inline">
                                            <span>
                                            {" "}
                                            /{" "}
                                            </span>
                                        </FrontText>
                                        <Link
                                            key={id}
                                            href={el.href}
                                            title={el.title}
                                            className="text-gray-text hover:text-heading-blue transition-all ease-in-out duration-150 font-medium"
                                        >
                                        <FrontText textSize='12/15' className="inline">
                                            {el.title}
                                        </FrontText>
                                        </Link>
                                    </React.Fragment>
                                );
                            })}
                        </nav>
                    </Column>
                </Row>
            </Container>
        </div>
    );
};

export default FrontBreadcrumbs;

import FrontText from "../FrontText";
import Container from "@/Components/Grid/Container";
import Row from "@/Components/Grid/Row";
import Column from "@/Components/Grid/Column";
import { Link } from "@inertiajs/react";
import resolveLocaleRoute from "@/Helpers/resolveLocaleRoute";
import { useState, useEffect } from "react";

import { Trans, useTranslation } from "react-i18next";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client/react/hooks/hooks.cjs";

const FrontHeaderMenu = ({ nav, headerHeight, mobileVisible }) => {
    const { t, i18n } = useTranslation();

    const checkIfActive = (href) => {
        const urlWithParams = href.split(",").map(text => text.trim());
        
        if (urlWithParams.length > 1) {
            let url = urlWithParams[0];
            let params = urlWithParams[1] || undefined;
            let singleRoute = "front.news.single";
            let provinceRoute = "front.warehouses";
            
            if (url == provinceRoute) {
                if (
                    (url == provinceRoute &&
                        route().current(provinceRoute + ".*") &&
                        params == route().params.searchRegion) ||
                    (route().current() === provinceRoute &&
                        params == route().params.searchRegion)
                ) {
                    return true;
                }

                return false;
            }

            if (
                route().current(url + ".*") ||
                (route().current() === url && params == route().params.taxonomy)
            ) {
                return true;
            }

            if (
                (url == "front.news.loop" &&
                    route().current(singleRoute + ".*")) ||
                (route().current() === singleRoute &&
                    params == route().params.taxonomy)
            ) {
                return true;
            }

            return false;
        }

        if (route().current(href + ".*") || route().current() === href) {
            return true;
        }

        return false;
    };

    const [isSubMenuVisible, setIsSubMenuVisible] = useState({});

    const hadleSubMenu = (e, index) => {
        if (mobileVisible) {
            e.preventDefault()
        }
        setIsSubMenuVisible((prev) => {
            return {
                ...isSubMenuVisible,
                [index]: !prev[index]
            }
        })
    }

    return (
        <div className={`lg:static fixed ${mobileVisible ? 'left-0' : '-left-full'} top-0 transition-all ease-in-out duration-300 lg:!pt-0 lg:h-full h-full lg:w-auto w-full lg:bg-none bg-new-blue`} style={{ paddingTop: headerHeight }}>
            <nav aria-label="Main" className="lg:overflow-hidden h-full w-full overflow-scroll">
                <ul>
                    {nav.map((elem, index) => {
                        return (
                            <li
                                key={index}
                                className={`lg:inline-block lg:[&:hover_.mega-menu]:opacity-100 lg:[&:hover_.mega-menu]:pointer-events-auto lg:[&:hover_.mega-menu]:select-auto ${elem.hasSubmenu && 'lg:mb-0 mb-c7'} `}
                            >
                                <Link
                                    href={resolveLocaleRoute(t(elem.link), null, i18n.resolvedLanguage)}
                                    {...(elem.hasSubmenu && { onClick: (e) => { hadleSubMenu(e, index) } })}
                                    className={`lg:inline-block flex items-center gap-3 xl:px-c18 lg:px-3 lg:py-c34 ${!elem.hasSubmenu ? 'py-c13' : 'pt-c13 pb-c7'} px-c30 ${checkIfActive(t(elem.link))
                                        ? "text-yellow"
                                        : "text-white"
                                        } hover:text-yellow transition-all ease-in-out duration-200`}
                                    title={t(elem.title)}
                                >
                                    <FrontText
                                        textSize="16/20-nav"
                                        className="lg:font-medium font-bold uppercase"
                                    >
                                        <span>{t(elem.title)}</span>
                                    </FrontText>
                                    {elem.hasSubmenu && (
                                        <svg className={`lg:hidden shrink-0 transition-all ease-in-out duration-300 ${isSubMenuVisible[index] && 'rotate-180'}`} width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M9.25089 11.8758C9.05167 12.0748 8.78162 12.1866 8.50005 12.1866C8.21849 12.1866 7.94844 12.0748 7.74922 11.8758L3.74147 7.8695C3.54224 7.67018 3.43035 7.39988 3.43042 7.11806C3.43049 6.83625 3.5425 6.566 3.74182 6.36677C3.94114 6.16755 4.21144 6.05566 4.49326 6.05573C4.77508 6.05579 5.04532 6.16781 5.24455 6.36713L8.50005 9.62263L11.7556 6.36713C11.9559 6.17349 12.2242 6.06627 12.5028 6.06855C12.7814 6.07084 13.0479 6.18246 13.245 6.37936C13.4421 6.57627 13.554 6.8427 13.5565 7.12129C13.5591 7.39987 13.4521 7.66831 13.2586 7.86879L9.25159 11.8765L9.25089 11.8758Z" fill="white" />
                                            </g>
                                        </svg>
                                    )}
                                </Link>

                                {elem.hasSubmenu && (
                                    <div
                                        className={`mega-menu drop-shadow-mega-menu lg:border-b lg:border-dark-blue-standard lg:select-none lg:transition-all lg:ease-in-out lg:duration-300 lg:opacity-0 lg:pointer-events-none lg:absolute lg:left-0 lg:top-full lg:bg-white lg:w-full lg:py-10 transition-all ease-in-out duration-300 transform origin-top lg:h-auto lg:transform-none ${isSubMenuVisible[index] ? 'scale-y-100 h-full' : 'scale-y-0 h-0'}`}
                                    >
                                        <Container className="lg:!mx-auto !m-0">
                                            <Row className="justify-center gap-x-6">
                                                {elem.hasSubmenu.map(
                                                    (column, index) => {
                                                        return (
                                                            <Column
                                                                xl={3}
                                                                key={index}
                                                            >
                                                                <div className="lg:mx-0 -mx-3">
                                                                    <FrontText
                                                                        textSize="16/20-m-14/17"
                                                                        customLinkStyles
                                                                        className={`lg:uppercase font-bold ${checkIfActive(
                                                                            t(
                                                                                column.link
                                                                            )
                                                                        )
                                                                            ? "text-yellow"
                                                                            : "lg:text-dark-blue-standard text-white"
                                                                            } lg:underline hover:text-yellow duration-200 ease-in-out transition-all lg:mb-6`}
                                                                    >
                                                                        {column.title ? (
                                                                            <Link
                                                                                className="flex lg:py-0 py-1.5 lg:px-0 pl-10 pr-15"
                                                                                href={resolveLocaleRoute(
                                                                                    t(
                                                                                        column.link
                                                                                    ),
                                                                                    null,
                                                                                    i18n.resolvedLanguage
                                                                                )}
                                                                            >
                                                                                <span>
                                                                                    {t(
                                                                                        column.title
                                                                                    )}
                                                                                </span>
                                                                            </Link>
                                                                        ) : (
                                                                            <span className="opacity-0 pointer-events-none select-none lg:inline hidden">
                                                                                .
                                                                            </span>
                                                                        )}
                                                                    </FrontText>

                                                                    {column.columnLinks &&
                                                                        column.columnLinks.map(
                                                                            (
                                                                                links,
                                                                                index
                                                                            ) => {
                                                                                return (

                                                                                    t(links.title) !== 'graphql' ?
                                                                                        <FrontText
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                            textSize="14/17"
                                                                                            customLinkStyles
                                                                                            className={`lg:font-medium font-bold ${checkIfActive(
                                                                                                t(
                                                                                                    links.link
                                                                                                )
                                                                                            )
                                                                                                ? "text-yellow"
                                                                                                : "lg:text-dark-blue-standard text-white"
                                                                                                } hover:text-yellow duration-200 ease-in-out transition-all lg:mb-c18`}
                                                                                        >
                                                                                            <Link
                                                                                                className="flex lg:py-0 py-1.5 lg:px-0 pl-12 pr-15"
                                                                                                href={resolveLocaleRoute(
                                                                                                    t(
                                                                                                        links.link
                                                                                                    ),
                                                                                                    null,
                                                                                                    i18n.resolvedLanguage
                                                                                                )}
                                                                                            >
                                                                                                <span>
                                                                                                    {t(
                                                                                                        links.title
                                                                                                    )}
                                                                                                </span>
                                                                                            </Link>
                                                                                        </FrontText>
                                                                                        :
                                                                                        t(links.title) == 'graphql' &&
                                                                                        <GraphQlSubMenus key={index} target={t(links.link)} currentLanguage={i18n.resolvedLanguage} />

                                                                                );
                                                                            }
                                                                        )}
                                                                </div>
                                                            </Column>
                                                        );
                                                    }
                                                )}
                                            </Row>
                                        </Container>
                                    </div>
                                )}
                            </li>
                        );
                    })}
                </ul>
            </nav>
        </div>
    );
};

const GraphQlSubMenus = ({ target = '', currentLanguage }) => {
    const tax = ['raporty', 'blog', 'aktualnosci'];
    const taxEn = ['reports', 'blog-en', 'news'];

    const LATEST_POSTS_FROM_TAX = gql`
        query GetPosts($categoryName: String!, $lang: LanguageCodeFilterEnum!) {
            posts(
                first: 2
                where: { categoryName: $categoryName, status: PUBLISH, language: $lang }
            ) {
                edges {
                    node {
                        id
                        title
                        slug
                    }
                }
            }
        }
    `;

    const { loading: loading_reports, error: error_reports, data: data_reports } = useQuery(LATEST_POSTS_FROM_TAX, {
        variables: {
            categoryName: currentLanguage.toUpperCase() === 'PL' ? tax[0] : taxEn[0], lang: currentLanguage.toUpperCase()
        },
        ssr: false,
    });

    const { loading: loading_blog, error: error_blog, data: data_blog } = useQuery(LATEST_POSTS_FROM_TAX, {
        variables: {
            categoryName: currentLanguage.toUpperCase() === 'PL' ? tax[1] : taxEn[1], lang: currentLanguage.toUpperCase()
        },
        ssr: false,
    });

    const { laoding: loading_news, error: error_news, data: data_news } = useQuery(LATEST_POSTS_FROM_TAX, {
        variables: {
            categoryName: currentLanguage.toUpperCase() === 'PL' ? tax[2] : taxEn[2], lang: currentLanguage.toUpperCase()
        },
        ssr: false,
    });

    if (loading_reports || loading_blog || loading_news) {
        return (
            <div className="animate-pulse">
                <div className="flex flex-col gap-2">
                    <div className="skeleton-animation h-5 w-3/4"></div>
                    <div className="skeleton-animation h-5 w-1/2"></div>
                </div>
            </div>
        )
    }

    if (target == 'reports' && data_reports) {
        return (
            data_reports.posts.edges.map((item, key) => {
                const { id, slug, title } = item.node
                return (
                    <FrontText
                        key={
                            slug + key
                        }
                        textSize="14/17"
                        customLinkStyles
                        className={`lg:font-medium font-bold lg:text-dark-blue-standard text-white hover:text-yellow duration-200 ease-in-out transition-all lg:mb-c18`}
                    >
                        <Link
                            className="flex lg:py-0 py-1.5 lg:px-0 pl-12 pr-15 lg:mb-c18"
                            href={resolveLocaleRoute('front.news.single', { taxonomy: currentLanguage == 'pl' ? tax[0] : taxEn[0], slug: slug })}
                        >
                            {title}
                        </Link>
                    </FrontText>
                )
            })
        )
    }
    if (target == 'blog' && data_blog) {
        return (
            data_blog.posts.edges.map((item, key) => {
                const { id, slug, title } = item.node
                return (
                    <FrontText
                        key={
                            slug + key
                        }
                        textSize="14/17"
                        customLinkStyles
                        className={`lg:font-medium font-bold lg:text-dark-blue-standard text-white hover:text-yellow duration-200 ease-in-out transition-all lg:mb-c18`}
                    >
                        <Link
                            className="flex lg:py-0 py-1.5 lg:px-0 pl-12 pr-15 lg:mb-c18"
                            href={resolveLocaleRoute('front.news.single', { taxonomy: currentLanguage == 'pl' ? tax[1] : taxEn[1], slug: slug })}
                        >
                            {title}
                        </Link>
                    </FrontText>
                )
            })
        )
    }
    if (target == 'news' && data_news) {
        return (
            data_news.posts.edges.map((item, key) => {
                const { id, slug, title } = item.node
                return (
                    <FrontText
                        key={
                            slug + key
                        }
                        textSize="14/17"
                        customLinkStyles
                        className={`lg:font-medium font-bold lg:text-dark-blue-standard text-white hover:text-yellow duration-200 ease-in-out transition-all lg:mb-c18`}
                    >
                        <Link
                            className="flex lg:py-0 py-1.5 lg:px-0 pl-12 pr-15 lg:mb-c18"
                            href={resolveLocaleRoute('front.news.single', { taxonomy: currentLanguage == 'pl' ? tax[2] : taxEn[2], slug: slug })}
                        >
                            {title}
                        </Link>
                    </FrontText>
                )
            })
        )
    }

}

export default FrontHeaderMenu;

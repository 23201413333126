import { useTranslation, } from 'react-i18next';
// import route from '@ziggyJs';
import { Ziggy } from '@/ziggy';

const resolveLocaleRoute = (url, params, currentLang = 'pl') => {
    // const { i18n } = useTranslation();
    
    // // const currentRoute = route().current();
    // // console.log(currentRoute)
    // const currentLang = i18n.resolvedLanguage

    // const urlWithParams = url.split(',');

    // if (urlWithParams.length > 1) {
    //     url = urlWithParams[0].replace(/\s/g, '');
    //     params = urlWithParams[1].replace(/\s/g, '');
    // }
    
    // if(currentLang === 'pl'){
    //     return route(url, params, undefined, Ziggy);
    // }
    
    // return route(url+'.en', params, undefined, Ziggy)

    const urlWithParams = url.split(',');

    if (urlWithParams.length > 1) {
        url = urlWithParams[0].replace(/\s/g, '');
        params = urlWithParams[1].replace(/\s/g, '');
    }

    if (currentLang === 'pl') {
        return route(url, params, undefined, Ziggy);
    }

    return route(url + '.en', params, undefined, Ziggy);
}

export default resolveLocaleRoute;
const FrontCheckboxInput = ({ className = "", ...props }) => {
    return (
        <input
            {...props}
            type="checkbox"
            className={
                "md:h-8 md:w-8 w-7 h-7 rounded-4 border-3 border-standard-black bg-transparent focus:ring-2 focus:ring-standard-black focus:ring-offset-white focus:ring-offset-2 focus:border-none text-standard-black transition-all ease-in-out duration-200 cursor-pointer" +
                className
            }
        />
    );
};

export default FrontCheckboxInput;

import FrontText from "../FrontText";

const FrontClassicButton = ({
    className = "",
    type = "button",
    children,
    disabled,
    displayAsButton = false,
    ...props
}) => {
    const styles = `inline-flex bg-[#FFF500] text-light-blue border-light-blue hover:text-white hover:border-[#FFF500] hover:bg-transparent items-center sm:px-c18 px-3 py-[7px] border rounded-lg gap-[10px] transition ease-in-out duration-150 hover:opacity-90 min-h-[40px] justify-center ${
        disabled && "opacity-50 cursor-not-allowed"
    } ${className}`;

    if (displayAsButton) {
        return (
            <button
                {...props}
                type={type}
                className={styles}
                disabled={disabled}
            >
                <FrontText textSize={"13/16"} className="font-semibold">
                    {children}
                </FrontText>
            </button>
        );
    } else {
        return (
            <div
                {...props}
                type={type}
                className={styles}
                disabled={disabled}
            >
                <FrontText textSize={"13/16"} className="font-semibold">
                    {children}
                </FrontText>
            </div>
        );
    }
};

export default FrontClassicButton;

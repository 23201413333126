import FrontText from "../../FrontText";

const FrontContactAcceptance = ({ children, popupAcceptance = false, className = "", ...props }) => {
    return (
        <div className={"flex gap-c10 " + className}>
            <input
                {...props}
                type="checkbox"
                className={
                    "h-5 w-5 rounded-5 border-2 border-light-blue bg-transparent focus:ring-2 focus:ring-light-blue focus:ring-offset-white focus:ring-offset-2 focus:border-none text-light-blue transition-all ease-in-out duration-200 cursor-pointer shadow-[inset_0px_0px_10px_0px_rgba(0,0,0,0.25)]"
                }
            />

            <FrontText textSize="10/12" className={`${popupAcceptance ? 'text-new-blue' : 'text-black font-medium'}`}>
                <label htmlFor={props.name} className="cursor-pointer">
                    {children}
                </label>
            </FrontText>
        </div>
    );
};

export default FrontContactAcceptance;

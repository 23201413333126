import { forwardRef, useEffect, useRef } from 'react';

export default forwardRef(function TextInput({ type = 'text', className = '', isFocused = false, ...props }, ref) {
    const input = ref ? ref : useRef();

    useEffect(() => {
        if (isFocused) {
            input.current.focus();
        }
    }, []);

    return (
        <input
            {...props}
            type={type}
            className={
                'focus:ring-0 focus:ring-offset-2 focus:ring-offset-light-blue transition-all ease-in-out duration-300 shadow-sm px-3 py-c15 text-14 leading-c17 text-light-blue font-medium italic border-0 bg-EBEBEB mb-c15 ' +
                className
            }
            ref={input}
        />
    );
});

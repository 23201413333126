import { Link } from "@inertiajs/react";
import FrontText from "../FrontText";
import resolveLocaleRoute from "@/Helpers/resolveLocaleRoute";
import { useTranslation } from "react-i18next";

const FrontFooterMenu = ({
    firstColLinks,
    secondColLinks,
    thirdColLinks,
    fourthColLinks,
}) => {
    const { i18n } = useTranslation();

    const linksArr = [
        firstColLinks,
        secondColLinks,
        thirdColLinks,
        fourthColLinks,
    ];

    const checkIfActive = (href, params) => {
        if (route().current(href + ".*") || route().current() === href) {
            if (route(href, params) === route(route().current(), route().params)) {
                return true
            } {
                return false
            }
        }
        return false;
    };

    return (
        <nav className="lg:flex lg:justify-between grid grid-cols-2 lg:w-full w-max lg:gap-0 sm:gap-x-10 gap-x-3 lg:mx-0 sm:mx-auto -mx-2 gap-y-12">
            {linksArr.map(
                (column, index) =>
                    column && (
                        <ul key={index} className="sm:pl-8 pl-6">
                            {column.map((el, index) => {
                                return (
                                    <li
                                        key={el.title + "-" + index}
                                        className={`relative before:w-c10 before:h-c10 before:bg-yellow before:rounded-full before:absolute before:-left-[21px] before:top-[13.5px] after:absolute after:w-0.5 after:h-full after:top-[13.5px] after:-left-[17px] after:bg-yellow last:after:hidden hover:text-yellow transition-all ease-in-out duration-200 ${
                                            checkIfActive(el.href, el.params)
                                                ? "text-yellow"
                                                : "text-white"
                                        }`}
                                    >
                                        <Link
                                            href={resolveLocaleRoute(el.href, el.params, i18n.resolvedLanguage)}
                                            title={el.title}
                                            className="py-c10 block"
                                        >
                                            <FrontText textSize="14/17">
                                                {el.title}
                                            </FrontText>
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    )
            )}
        </nav>
    );
};

export default FrontFooterMenu;

import { forwardRef, useEffect, useRef } from 'react';

export default forwardRef(function TextInput({ type = 'text', className = '', isFocused = false, ...props }, ref) {
    const input = ref ? ref : useRef();

    useEffect(() => {
        if (isFocused) {
            input.current.focus();
        }
    }, []);

    return (
        <input
            {...props}
            type={type}
            className={
                'focus:ring-new-blue border-new-blue focus:border-new-blue focus:ring-2 focus:ring-offset-2 focus:ring-offset-white transition-all ease-in-out duration-300 shadow-sm px-6 py-4 lg:placeholder-black placeholder:[#5E5E5E] italic text-14 leading-c17 text-standard-black font-medium ' +
                className
            }
            ref={input}
        />
    );
});

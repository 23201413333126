import Container from "@/Components/Grid/Container";
import FrontTextInput from "./FrontTextInput";
import { useForm, usePage } from "@inertiajs/react";
import Row from "@/Components/Grid/Row";
import Column from "@/Components/Grid/Column";
import FrontCheckboxInput from "./FrontCheckboxInput";
import FrontText from "../FrontText";
import { Trans, useTranslation } from 'react-i18next';
import WebpImage from "../Blocks/WebpImage";
import { useEffect, useState } from "react";

const FrontNewsletter = ({image, quoteKey}) => {
    const { t, i18n } = useTranslation();
    const { error } = usePage().props?.flash;

    const submitRoute = i18n.resolvedLanguage === 'pl'
        ? 'front.newsletter.signup'
        : 'front.newsletter.signup.en';

    const { data, wasSuccessful, setData, post, reset } = useForm({
        email: "",
        acceptance: false,
        recaptcha_token: "",
    });

    const submit = async (e) => {
        e.preventDefault();

        const token = await grecaptcha.execute(import.meta.env.VITE_RECAPTCHA_SITE_KEY, { action: 'submit' });
        setData("recaptcha_token", token);
    };

    useEffect(() => {
        if(data.recaptcha_token){
            post(route(submitRoute), {
                preserveScroll: true,
                onSuccess: () => {
                    reset();
                },
            });
        }
    }, [data.recaptcha_token])

    const transLink = {
        1: <a title={t('footer.privacy_policy')} target="_blank" href={`/storage/savills_polityka_prywatnosci.pdf`} className="text-dark-blue underline">{t('footer.privacy_policy')}</a>
    }

    return (
        /* =================== [Dodatek, zmiana odstępów od newslettera w footerze #5 zmienić klasę wrappera na -> '-mb-40 lg:pt-15 pt-10 z-30 relative'] =================== */
        <Container className="-mb-40 pt-c78 z-30 relative">
            <Row>
                {image && quoteKey && (
                    <Column>
                        <Row className='justify-between xl:px-28 lg:px-24 md:px-14 flex-wrap-reverse'>
                            <Column xl={3} lg={3} md={4} className='md:px-0 px-8'>
                                <WebpImage
                                    className='mr-auto'
                                    sources={[
                                        {
                                            width: 768,
                                            path: `/storage/images/${image.name}-size-418`,
                                            mime: 'jpg'
                                        },
                                        {
                                            width: 1,
                                            path: `/storage/images/${image.name}-size-767`,
                                            mime: 'jpg'
                                        }
                                    ]}
                                    alt={image.alt}
                                    fallback={image.src}
                                    seo={{
                                        width: 210,
                                        height: 317
                                    }}
                                />
                            </Column>
                            <Column xl={9} lg={9} md={8} className='flex justify-center items-center md:mb-0 mb-10'>
                                <div className="md:mx-4">
                                    <FrontText textSize={'24/27'} className="font-domine text-heading-blue font-bold text-center">
                                        <Trans i18nKey={quoteKey}/>
                                    </FrontText>
                                </div>
                            </Column>
                        </Row>
                    </Column>
                )}
                <Column>
                    <div className="relative max-w-full">
                        <img
                            src="/storage/icons/newsletter-icon.svg"
                            width={65}
                            height={65}
                            alt=""
                            className="absolute -top-[32.5px] left-1/2 transform -translate-x-1/2 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.25)] rounded-full"
                        />
                        <div className="bg-white border-3 border-new-blue rounded-10 xl:py-25 lg:py-20 md:py-16 pt-14 pb-10 px-8 xl:px-28 lg:px-24 md:px-14">
                            <form
                                onSubmit={submit}
                                className="flex flex-wrap gap-y-10 justify-between"
                            >
                                <div className="md:w-1/2 w-full">
                                    <FrontText
                                        className="text-heading-blue font-bold font-domine md:text-start text-center"
                                        textSize="28/32"
                                    >
                                        <span>{t('newsletter.heading')} </span>
                                        <span className="">{t('newsletter.subHeading')}</span>
                                    </FrontText>

                                    <FrontText
                                        textSize="16/20"
                                        className="text-standard-black font-medium mt-5 md:text-start text-center"
                                    >
                                        <p>
                                            <Trans i18nKey='newsletter.description'/>
                                        </p>
                                    </FrontText>
                                </div>

                                <div className="lg:w-2/5 md:w-5/12 w-full">
                                    <div>
                                        <FrontTextInput
                                            id="newsletter-email"
                                            type="email"
                                            name="email"
                                            value={data.email}
                                            placeholder={t('newsletter.placeholder')}
                                            className="w-full"
                                            onChange={(e) =>
                                                setData("email", e.target.value)
                                            }
                                            required
                                        />
                                    </div>

                                    <FrontText textSize={`12/15`} className="text-new-blue my-3">
                                        <p>
                                            {t('popup.acceptance1')}
                                        </p>
                                    </FrontText>
                                    <FrontText textSize={`12/15`} className="text-new-blue mb-3">
                                        <Trans i18nKey={'popup.acceptance2'} components={transLink} />
                                    </FrontText>
                                    
                                    <div className="md:mt-c10 mt-5 flex md:gap-c10 gap-c14">
                                        <FrontCheckboxInput
                                            id="newsletter-acceptance"
                                            name="acceptance"
                                            checked={data.acceptance}
                                            required
                                            onChange={(e) =>
                                                setData("acceptance", e.target.checked)
                                            }
                                        />

                                        <FrontText
                                            textSize="11/13"
                                            className="text-standard-black italic font-medium"
                                        >
                                            <label htmlFor="newsletter-acceptance" className=" cursor-pointer">
                                                <p>
                                                    {t('newsletter.acceptance')}
                                                </p>
                                            </label>
                                        </FrontText>
                                    </div>

                                    <button
                                        type="submit"
                                        className="bg-30548A md:h-14 h-10 w-40 rounded-12 mx-auto block mt-c21 border-2 border-30548A text-white hover:bg-white hover:text-30548A transition-all ease-in-out duration-300"
                                    >
                                        <FrontText
                                            textSize="14/17"
                                            className="uppercase font-semibold"
                                        >
                                            {t('newsletter.send')}
                                        </FrontText>
                                    </button>
                                    {wasSuccessful && (<p className="mt-4 text-lime-600">{t('newsletter.success_message')}</p>)}
                                    {error && <p className="text-danger text-12 font-bold mt-4">{error}</p>}
                                </div>
                            </form>
                        </div>
                    </div>
                </Column>
            </Row>
        </Container>
    );
};

export default FrontNewsletter;
